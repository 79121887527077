// --------------------------------------------------
// Variables
// --------------------------------------------------

// Fonts
$fontTitle: 'Manrope', 'sans-serif';
$fontTitleSerif: 'Manrope', 'sans-serif';
$fontText: 'Arial', 'sans-serif';
$fontTextLight: 'Arial', 'sans-serif';

// Accents Colors
$colorAccent1: #012E5D;
$colorAccent2: #546EB7;
$colorAccent3: #EAEFF2;
$colorAccent4: #FEB32B;

// Texts
$colorTextDark: #3F4040;
$colorTextRegular: #3F4040;
$colorTextLight: #fff;

// Blocks and Text
$dark: #242424;
$grey: #F9F9F9;
$darkGrey: #E6E6E6;
$darkerGrey: #7B7684;
$light: #F8F8F8;
$lighter: #F8F8F8;
$alternateWhite: #F8F8F8;
$white : #fff;

$colorSuccess: #56C568;

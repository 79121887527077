// --------------------------------------------------
// Btns components
// --------------------------------------------------
.site-btn,
[type="submit"] {
  display: inline-block;
  padding: 10px 16px;
  border: 1px solid $colorAccent1;
  background: $colorAccent1;
  color: #fff;
  font-family: $fontText;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  line-height: 25px;
  letter-spacing: -.025em;
  text-align: center;
  cursor: pointer;
  transition: .3s;
  transition-duration: .3s;
  -webkit-appearance: none;
  border-radius: 8px;

  &:hover {
    opacity: .75;
  }

  &.is-yellow {
    background: $colorAccent4;
    border-color: $colorAccent4;
  }

  &.is-outlined {
    background: transparent;
    color: $colorAccent1;
    font-family: $fontTitleSerif;
    opacity: 1;

    &:hover {
      color: #fff;
      background: $colorAccent1;
    }

    &.reverted-outlined {
      color: $white;
      border-color: $white;

      &:hover {
        border-color: $colorAccent1;
      }
    }
  }

  &.is-bigger {
    min-width: 200px;
    padding: 15px 25px;
    font-size: 18px;
  }

  &.is-alternate {
    border-color: $colorAccent3;
    background: $colorAccent3;
  }
}

.site-link-btn {
  //font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: $colorAccent1;
}

//
.page-wrapper-grid {
  padding-top: 9rem;

  @media (max-width: 1100px) {
    padding-top: 7rem;
  }
}

.box {
  display: block;
  padding: 2rem;
  color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  text-align: center;
  width: 100%;
  font-size: 1rem;
}

.full-grid {
  .box {
    padding: 1rem 0;
  }
}

.overflow-free-scroll {

  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: stretch;
  align-items: stretch;
  overflow-x: scroll;
  overflow-y: hidden;
  width: calc((100vw - (100vw - 1190px) / 2));

  a {
    min-width: calc((1190px / 2) - (2.5rem / 2));
    margin-right: 2.5rem;
    color: inherit;

    @media (max-width: 1190px) {
      width: auto;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}

.overflow-free-scroll-offset {

  position: relative;
  transform: translate(calc((1190px / 2) + 1.5rem), 0);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: stretch;
  align-items: stretch;
  overflow-x: scroll;
  overflow-y: hidden;
  width: calc((100vw - (100vw - 1190px) / 2) - calc(1190px / 2));

  @media (max-width: 1190px) {
    transform: translate(calc(100vw / 2), 0);
    width: calc(100vw - (100vw / 2));
  }

  a {
    min-width: calc((1190px / 2) - 1.5rem);
    margin-right: 2.5rem;
    color: inherit;

    @media (max-width: 1190px) {
      width: auto;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}

.overflow-free-scroll-offset-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  padding-right: 2.5rem;
}

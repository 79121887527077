html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

[#{$prefix}~='container'] {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: block;
  max-width: $container-width;
}

[#{$prefix}~='container-fluid'] {
  position: relative;
  width: 100%;
  padding: 5rem $site-gutter;
}

.container-fluid {
  position: relative;
  width: 100%;
  padding: 5rem $site-gutter;
}



//Footer
.site-footer {
  background-color: $white;
  //padding-top: 56px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-container {
    position: relative;
    width: 100%;
    max-width: $container-width;
    display: flex;
    justify-content: space-between;

    @media (min-width: 931px) and (max-width: 1023px) {
      padding-left: calc(80px - 5rem);
      padding-right: calc(80px - 5rem);
    }
  }

  .footer-top {
    padding-top: 56px;
    margin: 0 auto 40px;
    border-bottom: 1px solid $darkGrey;

    @media (max-width: 930px) {
      flex-direction: column;
    }

    @media( max-width: 767px) {
      padding: 80px 40px;
      margin-bottom: 0;
      border: none;
    }
  }

  .footer-top-left {
    max-width: 290px;

    @media (max-width: 767px) {
      margin-bottom: 48px;
    }

    .regular-text {
      font-family: $fontTitle;
      font-weight: 600;
      color: $colorAccent1;
    }

    .footer-logo {
      max-width: 100px;
      max-height: 100%;
      width: 100%;
      height: auto;
    }
  }

  .footer-top-right {
    padding: 52px 100px;
    text-align: center;
    border: 1px solid $colorAccent1;
    border-radius: 8px;

    @media (max-width: 767px) {
      padding: 32px 36px;
      border-color:  #EAEFF2;
    }

    .medium-title {
      margin: 0;
    }

    .small-title {
      margin-top: 7px;
      font-weight: normal;
    }

    .site-btn {
      margin-top: 32px;
    }
  }


  .footer-title {
    font-family: $fontTitle;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .5px;
    font-weight: 600;
    color: #929292;
    text-transform: uppercase;
    padding: 0 0 10px;
    border-bottom: 1px solid #525252;
    margin: 0 0 10px;
  }

  .footer-text {
    font-family: $fontTitle;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: .5px;
    color: white;
    margin: 0 0 20px;
  }

  .footer-newsletter-form {
    display: flex;
    margin-bottom: 20px;

    input[type=email] {
      flex-grow: 1;
      padding: 10px 15px;
      border: none;
      outline: 0;
      line-height: 24px;
    }

    button[type=submit] {
      padding: 10px 15px;
      border: none;
      outline: 0;
      font-weight: 700;
      color: white;
      line-height: 24px;
      background-color: $colorAccent1;
    }
  }

  .footer-bottom {

    @media (max-width: 930px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @media (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 0;
    }

    .footer-nav-links {
      width: 80%;
      grid-row-gap: 16px;


      @media (max-width: 767px) {
        margin-bottom: 48px;
      }
    }
  }

  .nav-link {
    display: block;
    width: fit-content;
    font-family: $fontTitle;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: .5px;
    font-weight: 500;
    color: $dark;
    padding: 6px 0;
    transition: color ease-out .2s;

    @media (max-width: 767px) {
      text-align: center;
      margin: auto;
    }

    i {
      margin-right: 12px;
      font-size: 16px;
    }

    &:hover {
      color: $colorAccent1;
    }
  }

  .footer-bottom {
    display: flex;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: .5px;
    color: rgba(white, .75);
    font-weight: 500;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-legal {
    margin: 0 10px 0 0;
    border-right: 1px solid white;
    padding-right: 10px;

    @media (max-width: 1023px) {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }
  }

  .footer-social {
    display: flex;

    a {
      margin-left: 20px;
      transition: opacity .3s ease-in-out;

      &:first-of-type {
        margin-left: 0;
      }

      &:hover {
        opacity: .7;
      }
    }
  }

  .footer-rights {
    color: rgba(white, .75);
    font-weight: 500;
    transition: color ease-out .2s;
    border-right: 1px solid white;
    padding-right: 10px;
    margin-right: 10px;

    &:last-child {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }

    @media (max-width: 1023px) {
      border-right: none;
      padding-right: 0;
      margin-right: 0;
    }

    &:hover {
      color: $colorAccent1;
    }
  }
}

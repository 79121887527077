// grid modifiers
[#{$prefix}~='grid'] {
  display: grid !important;
  grid-gap: $gutter;
  grid-template-columns: repeat(12, 1fr);
}

[#{$prefix}~='vertical-start'] {
  align-items: start;
}

[#{$prefix}~='vertical-center'] {
  align-items: center;
}

[#{$prefix}~='vertical-end'] {
  align-items: end;
}

[#{$prefix}~='between'] {
  justify-content: center;
}

[#{$prefix}~='gap-none'] {
  grid-gap: 0;
  margin-bottom: 0;
}

[#{$prefix}~='gap-column-none'] {
  grid-column-gap: 0;
}

[#{$prefix}~='gap-row-none'] {
  grid-row-gap: 0;
  margin-bottom: 0;
}

// column modifiers
[#{$prefix}~='first'] {
  order: -1;
}

[#{$prefix}~='last'] {
  order: 12;
}

[#{$prefix}~='hide'] {
  display: none !important;
}

[#{$prefix}~='show'] {
  display: block !important;
  display: initial !important;
  display: unset !important;
}

@for $i from 1 through $cols {
  // implicit columns
  [#{$prefix}~='grid'][#{$prefix}~='#{$i}\@sm'], [#{$prefix}~='grid'][#{$prefix}~='#{$i}\@md'], [#{$prefix}~='grid'][#{$prefix}~='#{$i}\@lg'], [#{$prefix}~='grid'][#{$prefix}~='#{$i}\@xl'] { 
    grid-template-columns: 12fr;
  }

  // explicit columns
  [#{$prefix}~='#{$i}\@sm'], [#{$prefix}~='#{$i}\@md'], [#{$prefix}~='#{$i}\@lg'], [#{$prefix}~='#{$i}\@xl'] { 
    grid-column: span 12;
  }
}

@for $i from 1 through $cols {
  // implicit columns
  [#{$prefix}~='grid'][#{$prefix}~='#{$i}'] {
    grid-template-columns: repeat($cols / $i, 1fr);
  }

  // explcit columns
  [#{$prefix}~='#{$i}'] {
    grid-column: span $i / span $i; // start / end
  }
}

@for $i from 1 through $cols {
  [#{$prefix}~='offset-#{$i}'] {
    grid-column-start: $i;
  }
}

@media (min-width: $sm-break) {
  @include column-generator('sm');
}

@media (min-width: $md-break) {
  @include column-generator('md');
}

@media (min-width: $lg-break) {
  @include column-generator('lg');
}

@media (min-width: $xl-break) {
  @include column-generator('xl');
}

[#{$prefix}~='flex'] {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -js-display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

[#{$prefix}~='fill'] {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

[#{$prefix}~='fit'] {
  flex-basis: auto;
}

[#{$prefix}~='float-center'] {
  margin-left: auto;
  margin-right: auto;
  display: block;
  float: none;
}

[#{$prefix}~='float-left'] {
  float: left;
}

[#{$prefix}~='float-right'] {
  float: right;
}

[#{$prefix}~='clear-fix']::after {
  content: '';
  display: table;
  clear: both;
}

[#{$prefix}~='text-left'] {
  text-align: left;
}

[#{$prefix}~='text-right'] {
  text-align: right;
}

[#{$prefix}~='text-center'] {
  text-align: center;
}

@for $i from 1 through $cols {
  [#{$prefix}~='#{$i}--max'] {
    max-width: (($container-width / 12) * $i) !important;
  }
}

[#{$prefix}~='full-width'] {
  width: 100%;
}

@mixin full-width-generator($suffix) {
  [#{$prefix}~='full-width-until\@#{$suffix}'] {
    width: 100% !important;
    max-width: 100% !important;
  }
}

@media (max-width: $sm-break) {
  @include full-width-generator('sm');
}

@media (max-width: $md-break) {
  @include full-width-generator('md');
}

@media (max-width: $lg-break) {
  @include full-width-generator('lg');
}

@media (max-width: $xl-break) {
  @include full-width-generator('xl');
}
// --------------------------------------------------
// Block
// --------------------------------------------------

.site-block {
  text-align: center;
  padding: 105px;

  @media (max-width: 1023px) {
    padding: 95px calc(80px - 5rem);
  }

  @media (max-width: 767px) {
    padding: calc(80px - 5rem) calc(32px - 1rem);
  }

  &.is-blue {
    background: $colorAccent3;

    .timeline-item-wrapper:last-of-type .timeline-item:after {
      background: $colorAccent3;
    }
  }

  &.is-grey {
    background: $grey;

    .timeline-item-wrapper:last-of-type .timeline-item:after {
      background: $grey;
    }
  }

  &.is-white {
    background: $white;

    .timeline-item-wrapper:last-of-type .timeline-item:after {
      background: $white;
    }
  }
}

// --------------------------------------------------
// Intro
// --------------------------------------------------
.block-intro {
  .container-fluid {
    padding-top: 0;
    padding-bottom: 0;
  }

  .block-intro-text {
    max-width: 1030px;
    margin: auto;

    h4,
    h4 strong{
      font-family: $fontText;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 27px;
      color: $darkerGrey;
    }
  }
}


// --------------------------------------------------
// Stats 001
// --------------------------------------------------

.block-stats {

  .block-intro-text {
    max-width: 575px;
    margin: 0 auto 80px;
  }

  .single-stat {

    &:not(:last-of-type) {
      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    .stat-title {
      margin: 0 auto 16px;

      font-family: $fontTitle;
      font-style: normal;
      font-weight: bold;
      font-size: 60px;
      line-height: 58px;
      color: $colorAccent4;
    }

    .stat-text {
      margin: 0 auto;
      max-width: 280px;
      font-family: $fontTitle;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      color: $colorAccent1;
    }
  }
}

// --------------------------------------------------
// Programs 001
// --------------------------------------------------

.block-programs {

  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }

  .block-title-container {

    @media (max-width: 767px) {
      padding-left: calc(32px - 1rem);
      padding-right: calc(32px - 1rem);
    }
  }

  .block-intro-text {
    max-width: 750px;
    margin: auto;
  }

  .programs-container {
    margin-top: 48px;

    @media (max-width: 767px) {
      padding: 0 10px;
    }
  }

  .program-card {
    display: flex;
    flex-direction: column;
    padding: 35px 48px;
    background: $white;
    border-radius: 8px;

    &.is-full {
      box-shadow: 0px 12.5216px 10.0172px rgba(171, 171, 171, 0.035), 0px 100px 80px rgba(171, 171, 171, 0.07);
    }

    .regular-text {
      color: $colorAccent1;
      margin: 18px auto 48px;
    }

    .program-intro {
      &.has-underline {
        margin-bottom: 0;

        &:after {
          content: '';
          display: block;

          width: 35px;
          height: 3px;

          margin: 24px auto 16px;

          background: $colorAccent1;
        }
      }
    }

    &.is-summary {
      border: 1px solid $colorAccent1;
    }
  }

  .program-image-container {
    width: 140px;
    height: 140px;
    margin: 0 auto 14px;
  }

  .program-title {
    margin: 0;
    font-family: $fontText;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 130%;
    color: $colorAccent1;
  }

  .info-container {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 60px;
    }

    p,
    .regular-text {
      margin: 0;
    }

    .info-title {
      color: $colorAccent4;
      font-weight: bold;
    }
  }

  .site-btn {
    margin: auto auto 0;
    width: fit-content;
  }
}

// --------------------------------------------------
// Partners 001
// --------------------------------------------------

.block-partners {
  .partner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-of-type) {
      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }

    img {
      max-width: 100%;
    }
  }
}

// --------------------------------------------------
// CTA 003
// --------------------------------------------------

.block-cta {

  .block-text,
  .big-title {
    max-width: 750px;
    margin: auto;

    &.is-full {
      max-width: 100%;
    }
  }

  .block-text{
    max-width: 750px;
  }

  .site-btn {
    margin-top: 48px;
  }
}

// --------------------------------------------------
// CONTENU 001
// --------------------------------------------------

.block-contenu {

  .block-text,
  .big-title {
    max-width: 750px;
    margin: auto;

    &.is-full {
      max-width: 100%;
    }
  }

  .block-text{
    max-width: 750px;

    &.is-accordeon {
      overflow: hidden;
      transition: max-height .5s ease-in-out;

      @media (max-width: 767px) {
        max-height: 800px;
      }

      &.is-visible {
        max-height: 2000px;
      }
    }

    figure {
      @media (max-width: 767px) {
        margin: 0;
      }

      iframe, {
        @media (max-width: 767px) {
          max-width: 100%;
        }
      }

      img {
        max-width: 100%;
      }
    }
  }

  .toggle-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    @media (min-width: 768px) {
      display: none;
    }

    &.is-active {
      img {
        transform: rotate(-180deg);
      }
    }

    img {
      margin-left: 5px;
      transition: transform .3s ease-in-out;
    }
  }

  .toggle-text {
    font-size: 18px;
    cursor: pointer;
  }
}

// --------------------------------------------------
// Timeline 001
// --------------------------------------------------

.block-timeline {
  .timeline-listing {
    position: relative;
    overflow: hidden;
    margin-top: 50px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 30px;
      width: 1px;
      height: 100%;
      background: $colorAccent4;
    }

    @media (min-width: 768px) {
      &:before {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .timeline-item-wrapper {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;

    &:last-of-type {
      .timeline-item {
        margin-bottom: 0;

        &:after {
          content: '';
          position: absolute;
          top: 30px;
          left: -45px;
          right: auto;
          width: 10px;
          min-height: 100%;
          //background: $grey;

          @media (min-width: 375px) {
            right: -75px;
          }

          @media (min-width: 768px) {
            left: -115px;
            right: auto;
          }
        }
      }
    }

    &:nth-of-type(even) {
      flex-direction: row-reverse;

      @media (min-width: 768px) {
        flex-direction: row;
      }

      &:last-of-type {
        .timeline-item {
          &:after {
            right: -45px;
            left: auto;

            @media (min-width: 375px) {
              right: -75px;
            }

            @media (min-width: 768px) {
              right: -115px;
            }
          }
        }
      }

      .timeline-item {
        text-align: right;

        @media (max-width: 767px) {
          text-align: left;
        }

        .item-point {
          left: -55px;
          right: auto;

          @media (min-width: 375px) {
            left: -85px;
          }

          @media (min-width: 768px) {
            right: -125px;
            left: auto;
          }
        }
      }
    }
  }

  .timeline-item {
    position: relative;
    width: calc(100% - 70px);
    margin-bottom: 50px;
    text-align: left;

    @media (min-width: 375px) {
      width: calc(100% - 100px);
    }

    @media (min-width: 768px) {
      width: calc(50% - 110px);
      text-align: left;
    }

    &:hover {
      .item-point {
        &::before {
          animation: 3s pulse infinite linear;
        }
      }
    }

    &:first-of-type {
      margin-top: 10px;
    }

    &:last-of-type {
      &:after {
        content: '';
        position: absolute;
        right: auto;
        left: -55px;
        width: 1px;
        //background: $white;

        @media (min-width: 375px) {
          left: -85px;
        }

        @media (min-width: 768px) {
          right: -125px;
          left: auto;
        }
      }
    }

    .item-point {
      position: absolute;
      right: auto;
      left: -55px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      z-index: 2;
      border: 8px solid $colorAccent4;
      border-radius: 50%;
      background-color: $white;
      box-sizing: border-box;
      //transition: border-width .4s ease;
      will-change: auto;

      @media (min-width: 375px) {
        left: -85px;
      }

      @media (min-width: 768px) {
        left: -125px;
        right: auto;
      }

      &:before {
        border: 0.4px solid $colorAccent4;
        content: "";
        width: 100%;
        display: block;
        position: absolute;
        height: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 20;
        border-radius: 100%;
        transform-origin: center center;
        opacity: 0;
        box-sizing: border-box;
      }

      @keyframes pulse {
        0% {
          transform: scale(3);
          opacity: 1;
        }
        50% {
          transform: scale(3.2);
          opacity: 0;
        }
        100% {
          transform: scale(3);
          opacity: 1;
        }
      }
    }

    .item-date {
      font-family: $fontTitle;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 180%;
      margin: 0;
      color: $colorAccent4;
    }

    .item-text {
      @extend .regular-text;
      font-weight: normal;

      p:first-of-type {
        margin-top: 0;
      }
    }
  }
}

// --------------------------------------------------
// SPLIT 001
// --------------------------------------------------
.block-split {
  padding: 0;

  .split-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    //margin-bottom: 35px;

    @media (min-width: 768px) {
      flex-direction: row;
      //margin-bottom: 75px;
    }

    @media (min-width: 1024px) {
      //margin-bottom: 150px;
    }


    &:last-of-type {
      margin-bottom: 0;
    }

    &:nth-of-type(even) {
      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }

      .item-col {
        text-align: center;

        @media (min-width: 768px) {
          text-align: right;
        }

        &:last-of-type {

          @media (min-width: 768px) {
            padding-right: 70px;
            padding-left: 70px;
          }

          @media (min-width: 1024px) {
            padding-right: 125px;
            padding-left: 125px;
          }
        }
      }
    }

    .item-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      min-height: 300px;
      text-align: center;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;


      @media (min-width: 768px) {
        width: 50%;
        min-height: 463px;
        text-align: left;
      }

      @media (max-width: 767px) {
        padding: 80px 32px;
      }

      &:last-of-type {

        @media (min-width: 768px) {
          padding-left: 70px;
          padding-right: 70px;
        }

        @media (min-width: 1024px) {
          padding-left: 125px;
          padding-right: 125px;
        }
      }
    }

    .item-title {
      margin: 0;
      text-align: left;

      &:after {
        margin: 32px 0;
      }
    }

    .item-text {
      text-align: left;
      p {
        margin: 0;
      }
    }
  }
}

// --------------------------------------------------
// TEAM 001
// --------------------------------------------------

.block-team {

  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }

  .block-title-container {

    @media (max-width: 767px) {
      padding-left: calc(32px - 1rem);
      padding-right: calc(32px - 1rem);
    }
  }

  .team-container {
    @media (max-width: 767px) {
      display: flex !important;
      overflow-x: scroll;
    }
  }

  .single-team {
    margin-bottom: 56px;

    @media (max-width: 767px) {
      margin: 0 36px;
    }

    .team-title {
      margin: 5px 0;
      font-size: 16px;
      color: $colorAccent1;
    }

    .team-text {
      margin: 0 auto;
      font-size: 14px;
      line-height: 27px;
      color: $colorAccent1;
      max-width: 200px;
    }
  }
}

.alert-success {
  background: #88c3ab;
  padding: 20px;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.parsley-errors-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.hs-error-msgs {
  display: none !important;
}

.submitted-message {
  color: $white;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto 90px;
  padding: 0 60px;

  &.refer-form {
    padding: 0;
    margin-bottom: 10px;

    .form-error {
      bottom: -15px;
    }

    .input-holder {
      margin-bottom: 10px;
    }
  }

  @media all and (max-width: 1024px) {
    padding: 0 35px;
    margin-bottom: 60px;
  }

  @media all and (max-width: 767px) {
    padding: 0;
    margin-bottom: 50px;
  }

  input,
  textarea,
  select {
    width: 100%;
    font-size: 14px;
    transition: all .3s ease-in-out;
    background: transparent;

    &:focus {
      border-color: $colorAccent2;
      outline: 0;
    }

    &.error {
      background: rgba(255, 0, 0, .13);
    }
  }

  input.error + .form-error,
  textarea.error + .form-error,
  select.error + .form-error {
    display: block;
  }

  select {
    position: relative;
    padding: 10px 0;
    border: none;
    border-bottom: 1px solid $dark;
    border-radius: 0;
    appearance: none;
  }

  &.refer-form {
    input,
    textarea {
      margin-bottom: 20px;
    }
  }

  textarea {
    padding: 10px;
    min-height: 135px;
    border: 1px solid $dark;
  }

  input {
    padding-bottom: 5px;
    border: 0;
    border-bottom: 1px solid $dark;
  }

  label {
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  #label-message-7bf79203-0e98-4752-9ad5-01713ce14872, #label-message-392ba9d3-9ca6-4d2d-971d-baaec2adf4e6 {
    display: inline-block;
  }

  &.refer-form {
    label {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }

  button {
    cursor: pointer;
    margin: 60px auto 0;

    @media all and (max-width: 767px) {
      margin-top: 50px;
    }
  }

  &.refer-form {
    button {
      margin: 20px auto 0;
    }
  }

  &.sidepanel-form {
    padding: 0;
    margin: 0;

    input {
      margin-bottom: 30px;

      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $dark;

      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $dark;

      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $dark;

      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $dark;

      }

    }

    input[type='submit'] {
      @extend .site-btn;
    }

    .submitted-message {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: $colorAccent1;
    }

    .hs-error-msgs {
      display: block !important;
      margin: -20px 0 20px;
      padding: 0;
      list-style: none;
      font-size: 14px;
      color: $colorAccent1;
    }

    .inputs-list {
      list-style: none;
      margin: 0;
      padding-left: 30px;

      li label {
        cursor: pointer;

        .hs-input[type=checkbox] {
          display: none;

          &+span {
            position: relative;

            &:before {
              position: absolute;
              top: 0;
              left: -30px;
              content: '';
              width: 16px;
              height: 16px;
              background: transparent;
              border-radius: 3px;
              border: 1px solid $colorAccent2;
              transition: all ease-out;
            }
          }

          &:checked+span:before {
            background: transparent url('/assets/images/icon-check.svg') no-repeat center center / 8px;
          }
        }
      }
    }
  }
}

.has-error {
  input,
  textarea {
    border-color: $colorAccent2;
  }

  &.message-field .form-error {
    bottom: initial;
    top: 30px;
    left: 10px;
  }

  .form-error {
    display: block;
  }
}

.form-error {
  display: none;
  position: absolute;
  bottom: -40px;
  color: $colorAccent2;
  user-select: none;
  pointer-events: none;
}

.inputs-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding: 0 30px;
  }
}

.input-holder {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-wrapper {
  position: relative;
  margin-top: 10px;

  .icon-angle-down {
    position: absolute;
    top: 50%;
    right: 0;

    display: block;
    transform: translateY(-50%);

    content: '';
  }
}

.half-inputs {
  width: calc(50% - 20px);
  margin-right: 40px;

  &:last-of-type {
    margin-right: 0;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0 0 40px;

    &:last-of-type {
      margin: 0;
    }
  }
}

.general-alert {

  p {
    text-align: center;
    margin: 0 auto 50px;
    font-size: 20px;

    &.error-alert {
      color: $colorAccent1;
    }

    &.success-alert {
      color: $colorAccent1;
    }
  }
}

.js-loader {
  display: none;
  height: 120px;
  width: 120px;
  margin: auto;
}

// GRID
.form-cols {
  display: flex;

  .form-col {
    width: 100%;
    padding: 0 25px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  .form-col-half {
    width: 50%;
  }
}

// Components
.site-form {

  .form-label {
    display: block;
  }

  .form-input-wrapper,
  .fields .field {
    position: relative;
    width: 100%;
  }

  .form-select {
    height: 50px;
  }

  .form-input,
  input,
  select,
  textarea {
    display: block;
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 27px;
    border: 2px solid $lighter;
    font-size: 14px;
    line-height: 1;
    outline: none;
    background-color: white;

    &.file-section {
      display: flex;
      justify-content: space-between;

      @media (max-width: 630px) {
        flex-direction: column;
      }
    }

    &:focus {
      /**
       * Focused input styles here.
       */
      // outline: none;
      // box-shadow: 0.2rem 0.8rem 1.6rem rgba($colorAccent1, 0.12);
    }
  }

  .input-label,
  label {
    /**
    * The label gets absolute positioning, and a calculated set of  sizes,
    * positioning, and transforms, based on relativity to the container element
    * and input text styles.
    */
    display: block;
    position: absolute;
    bottom: 50%;
    left: 1px;
    padding-bottom: 4px;
    color: $dark;
    font-family: inherit;
    font-size: 18px;
    font-weight: inherit;
    line-height: 1;
    opacity: 0;
    transform: translate3d(0, 50%, 0) scale(1);
    transform-origin: 0 0;
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
    visibility 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
    z-index 0ms 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  .form-input:placeholder-shown + .input-label,
  input:placeholder-shown + label {
    /**
     * While the placeholder is visible, we want to hide the label.
     */
    visibility: hidden;
    z-index: -1;
  }

  .form-input:not(:placeholder-shown) + .input-label,
  .form-input:focus:not(:placeholder-shown) + .input-label,
  input:not(:placeholder-shown) + label,
  input:focus:not(:placeholder-shown) + label
   {
    /**
     * While the placeholder is not shown - i.e. the input has a value - we want
     * to animate the label into the floated position.
     */
    visibility: visible;
    z-index: 1;
    opacity: 1;
    transform: translate3d(0, -100%, 0) scale(0.8);
    transition: transform 300ms, visibility 0ms, z-index 0ms;
  }
  .form-textarea,
  textarea {
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 27px;
    border: 2px solid $lighter;
    font-size: 14px;
    line-height: 1;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 3px;
    font-weight: 400;
    letter-spacing: .2px;
    color: #6D6D6D;
    height: 200px;
  }

  .site-btn,
  [type="submit"] {
    font-family: $fontTitleSerif;
    width: 100%;
    line-height: 1.5;
  }
}

// --------------------------------------------------
// wheelform
// --------------------------------------------------
.default-form {
  .site-form {
    .wf-group {
      display: inline-block;
      position: relative;
      margin-bottom: 20px;
      width: 100%;

      .wf-list-container {
        .wf-list-add {

        }
      }

      .wf-checkbox {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        .wf-field {
          display: inline-block;
        }
      }

      label {
        display: block;

        margin-bottom: 25px;
      }

      input {
        display: block;
        width: 100%;
        min-height: 60px;
        background-color: $white;
        border: 1px solid rgba($grey, .5);
        border-radius: 8px;

        padding-left: 15px;
        color: $dark;
      }

      label + input:not(:placeholder-shown), input:focus,
      label + textarea:not(:placeholder-shown), textarea:focus {
        top: -5px;
        transform: translateY(0);
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: $dark;
      }

      textarea {
        background-color: $white;
        width: 100%;
        min-height: 100px;
        padding-left: 15px;
        border: 1px solid rgba($grey, .5);
        border-radius: 8px;

        &:not(:placeholder-shown), &:focus {
          & + label {
            top: -5px;
            transform: translateY(0);
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $dark;
          }
        }
      }
    }

    input[name="wf-submit"] {
      @extend .site-btn;
      font-family: $fontTitleSerif;
      width: 100%;
      line-height: 1.5;
    }
  }
}


.newsletter-form {
  .site-form {
    .wf-group {
      display: inline-block;
      position: relative;
      margin-bottom: 20px;
      width: 69%;

      .wf-list-container {
        .wf-list-add {

        }
      }

      .wf-checkbox {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        .wf-field {
          display: inline-block;
        }
      }

      label {
        display: none;
      }

      input {
        display: block;
        width: 100%;
        min-height: 60px;
        background-color: $white;
        border: 1px solid rgba($grey, .5);
        border-radius: 8px;

        padding-left: 15px;
        color: $dark;
      }

      label + input:not(:placeholder-shown), input:focus,
      label + textarea:not(:placeholder-shown), textarea:focus {
        top: -5px;
        transform: translateY(0);
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: $dark;
      }

      textarea {
        background-color: $white;
        width: 100%;
        min-height: 100px;
        padding-left: 15px;
        border: 1px solid rgba($grey, .5);
        border-radius: 8px;

        &:not(:placeholder-shown), &:focus {
          & + label {
            top: -5px;
            transform: translateY(0);
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $dark;
          }
        }
      }
    }

    button[type="submit"] {
      display: inline-block;
      font-family: $fontTitleSerif;
      line-height: 1.5;
    }
  }
}

.alert-success {
  background: rgba(20, 181, 96, 0.5);
  width: 100%;
  text-align: center;
  padding: 15px 20px;
  border-radius: 3px;
  margin: 0 0 10px;
  font-weight: 600;
  color: white;
}

input#secretHoneypotApplication,
input#secretHoneypotSpontaneousApplication
{ display: none; }

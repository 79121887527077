.contact {
  .site-block {
    @media(max-width: 767px) {
      padding-left: calc(16px - 1rem);
      padding-right: calc(16px - 1rem);
      padding-bottom: 80px;
    }
  }
}

.basic-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  .contact-block {
    width: 100%;
    max-width: 460px;
    padding: 32px;
    border: 1px solid $colorAccent1;
    border-radius: 8px;
    text-align: left;

    @media (max-width: 1023px) {
      max-width: 100%;
      padding: 16px;
    }

    .medium-title {
      margin: 0 0 18px;
    }
  }

  .contact-info-wrapper {
    display: flex;
    align-items: center;

    &:first-of-type {
      margin-bottom: 14px;

      @media (max-width: 1023px) {
        margin-bottom: 20px;
      }
    }

    img {
      margin-right: 10px;

      @media (max-width: 767px) {
        width: 32px;
        margin-right: 8px;
      }
    }

    .regular-text {
      color: $dark;

      @media (max-width: 1023px) {
        margin: 0;
      }

      span {
        color: $colorAccent4;
      }
    }

    a {
      text-decoration: none;
      color: $dark;

      @media (max-width: 767px) {
        word-break: break-word;
      }
    }
  }

  .left-contact {
    margin-right: 70px;

    @media (max-width: 1023px) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
}


.contact-form-container {
  margin-top: 72px;

  label {
    position: relative;
    bottom: 0;
    left: 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: $colorAccent1;
    transform: none;
    opacity: 1;
  }

  input,
  select,
  textarea {
    border-radius: 8px;
    outline: 0;
    border: 0;
    margin-bottom: 0;
  }

  textarea {
    max-height: 300px;
    min-width: 100%;
    max-width: 100%;
  }

  .form-input-row {
    margin-bottom: 60px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .site-btn {
    display: flex;
    margin-left: auto;
    width: fit-content;

    @media (max-width: 767px) {
      margin: auto;
    }
  }
}

.contact-map-container {
  position: relative;
  height: 500px;
  width: 100%;

  .contact-map {
    height: 100%;
    width: 100%;
  }
}


.popup-bubble {
  display: block !important;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);

  background-color: white;
  padding: 16px;
  border-radius: 5px;
  font-family: sans-serif;
  overflow-y: auto;
  width: 100%;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);

  .small-title {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 16px;
    color: $colorAccent1;
  }

  .regular-text {
    @extend .regular-text;
    margin: 0;
  }
}

.popup-bubble-anchor {
  position: absolute;
  width: 100%;
  bottom: -45px;
  left: 170px;

  @media (max-width: 767px) {
    left: 1px;
    top: 150px;
  }
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translate(-135px, -68px) rotate(90deg);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;

  @media (max-width: 768px) {
    transform: translate(-50%, -135px) rotate(180deg);
  }
}

.popup-container {
  cursor: auto;
  height: 0;
  position: absolute;
  width: 250px;
  display: block !important;
}

// --------------------------------------------------
// Header
// --------------------------------------------------

.main-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 15px 48px;
  z-index: 50;
  background: transparent;
  background: $white;

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition: -webkit-transform 0.7s .3s ease;
    transition: transform 0.7s .3s ease;
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
    -webkit-backface-visibility: hidden;
    box-shadow: 0 1px 5px 0px rgba(0, 0, 0, .15);

    .header-logo {
      img {
        height: 57px;
      }
    }

    &.hide-nav-bar {
        -webkit-transform: translate(0, -100%);
        -ms-transform: translate(0, -100%);
        transform: translate(0, -100%);
        -webkit-backface-visibility: hidden;
    }
  }

  .header-container {
    display: flex;
    //max-width: $container-width;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }

  //.header-logo,
  //.header-right,
  //.header-nav {
  //  flex: 1;
  //  display: flex;
  //  justify-content: center;
  //}

  .header-logo {
    margin-right: auto;

    img {
      height: 76px;

      @media (max-width: 1060px) {
        height: 57px;
      }
    }
  }

  .header-nav {
    display: flex;
    align-items: center;

    @media (max-width: 1024px) {
      display: none;
    }

    .header-link {
      position: relative;
      font-family: $fontText;
      font-size: 16px;
      line-height: 27px;
      font-weight: normal;
      color: $dark;
      margin: 0 16px;

      transition: all 0.35s ease;

      @media (min-width: 1024px) and (max-width: 1060px) {
        font-size: 15px;
      }

      &:after,
      &:before {
        content: '';
        position: absolute;
        bottom: -2px;
        height: 2px;
        width: 0;
        background: $colorAccent4;

        transition: width .3s ease-in-out;
      }

      &:after {
        right: 0;
      }

      &:before {
        left: 0;
      }

      &:hover,
      &.is-active {
        &:after,
        &:before {
          width: 50%;
        }
      }

      @media (max-width: 1400px) {
        margin: 0 10px;
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;
    margin-left: auto;

    .header-phone {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 1px;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;
      margin: 0 20px 0 0;

      a {
        color: inherit;
        text-decoration: none;
        transition: all 0.35s ease;

        &:hover {
          color: $colorAccent1;
        }
      }

      @media (max-width: 1024px) {
        display: none;
      }
    }

    .site-btn {
      @media (max-width: 1024px) {
        display: none;
      }
    }

    .mobile-menu-trigger {
      display: none;
      width: 30px;
      height: 30px;
      cursor: pointer;
      background: url('/img/site/icon-burger.svg') no-repeat center center / contain;

      @media (max-width: 1024px) {
        display: block;
      }
    }
  }
}

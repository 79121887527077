.events-container {
  margin-top: 32px;

  &:first-of-type {
    margin-bottom: 80px;

    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }
}

.event-card {
  margin-bottom: 40px;
  border-radius: 8px;

  .event-image-holder {
    position: relative;
    border-radius: 8px 8px 0 0;
    overflow: hidden;

    .event-price {
      position: absolute;
      top: 16px;
      left: 16px;

      margin: 0;
      padding: 3px 8px;

      border-radius: 8px;
      background: $white;
      font-size: 14px;
      color: $dark;
      font-weight: bold;
    }

    img {
      width: 100%;
      max-width: 100%;
      vertical-align: middle;
    }
  }

  .event-content-holder {
    display: flex;
    padding: 24px 37px 24px 17px;
    background: $white;
    border-radius:0 0 8px 8px;

    .event-date {
      text-align: center;

      p {
        margin: 0;
      }

      .event-month {
        color: $colorAccent2;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
      }
    }

    .event-content {
      text-align: left;
      margin-left: 24px;

      .event-title {
        margin: 0;
        color: $dark;
        font-size: 20px;
        line-height: 27px;
      }

      .event-text {
        margin-top: 8px;
        margin-bottom: 0;
        color: $darkerGrey;
      }
    }
  }
}
